import React from "react"
import styled from "styled-components"
import bambooPanel from "../../resources/img/question/question-frame.png";
import bambooPanelMobile from "../../resources/img/bamboo-panels/panel-mobile.png";
import fonts from "./../../styles/fonts"
import colors from "./../../styles/colors"
import breakpoints from "../../styles/breakpoints";

const PageContainer = styled.div`
`;

const StyledContentContainer = styled.div`
  width: 100%;
  max-width: 854px;
  margin: auto;
  padding: 0;
`;

const ArticleContainer = styled.div`
  background: url(${bambooPanelMobile}) no-repeat;
  background-size: 100% auto;
  width: 100%;
  margin: 0 auto 20px auto;
  color: ${colors.black};
  height: 400px;
  max-width: 305px;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
  }
  
  h2 {
    ${fonts.gloryBold};
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;

    @media (min-width: ${breakpoints.md}) {
      margin-bottom: 8px;
    }
  }
  
  a {
    color: ${colors.black};
    text-decoration: none;
    padding-bottom: 2px solid ${colors.black};
    border-bottom: 1px solid ${colors.black};
    cursor: pointer;
  
    &:hover {
      color: ${colors.blue};
      border-color: ${colors.blue};
    }
  }
  
  img {
    max-width: 100%;
    margin-bottom: 40px;
  }

  @media (min-width: ${breakpoints.sm}) {
    background: url(${bambooPanel}) no-repeat;
    background-size: 100% 580px;
    margin: 0 auto 96px auto;
    height: 580px;
    max-width: 100%;
  }

  @media (min-width: ${breakpoints.lg}) {
    background: url(${bambooPanel}) no-repeat;
    background-size: 100% auto;
    margin: 0 auto 96px auto;
    height: 555px;
    max-width: 100%;
  }
`;

const ArticleContent = styled.div`
  width: 100%;
  margin: auto;
  max-width: 200px;

  @media (min-width: ${breakpoints.sm}) {
    padding: 44px 32px;
    height: 580px;
    max-width: 854px;
  }
  
  @media (min-width: ${breakpoints.lg}) {
    padding: 44px 76px;
    height: 555px;
    max-width: 854px;
  }
`;

const Frame = ({children}) => {
  return (
    <StyledContentContainer>
      <ArticleContainer>
        <ArticleContent>
          {children}
        </ArticleContent>
      </ArticleContainer>
    </StyledContentContainer>
  )
};

export default Frame;
