const ACCOUNT_KEY = "panacct";

const getAccount = () => {
  return window.localStorage.getItem(ACCOUNT_KEY);
};

const connectAccount = (accountData) => {
  if (accountData.length > 0) {
    window.localStorage.setItem(ACCOUNT_KEY, accountData[0]);
  } else {
    disconnectAccount();
  }
};

const disconnectAccount = () => {
  console.log("STORAGE DISCONNECT");

  window.localStorage.removeItem(ACCOUNT_KEY);
};

const storage = {
  getAccount,
  connectAccount,
  disconnectAccount
};

export default storage;

