import * as React from "react"
import styled from "styled-components"
import logoHome from "../../resources/img/logo/logo-small.svg";
import {Link} from "gatsby";

const LogoImage = styled.img`
  height: 64px;
`;

const Logo = () => {
  return (
    <Link to={"/"}>
      <LogoImage src={logoHome} alt={"Panda Dynasty"}/>
    </Link>
  )
}

export default Logo;
