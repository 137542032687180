import detectEthereumProvider from '@metamask/detect-provider';
import storage from "./storage";

const isInstalled = async () => {
  const provider = await detectEthereumProvider();

  console.log(provider);

  if (provider === window.ethereum) {
    return true;
  }

  console.log('Please install MetaMask!');

  return false;
};

const addEventListeners = () => {
  const ethereum = window.ethereum;

  ethereum.on('accountsChanged', (accounts) => {
    storage.connectAccount(accounts);
    window.location.reload();
  });

  ethereum.on('chainChanged', (chainId) => {
    window.location.reload();
  });
};

const getAccount = async () => {
  return window.ethereum
    .request({method: 'eth_requestAccounts'})
    .then((accounts) => {
      storage.connectAccount(accounts);

      return accounts;
    })
    .catch((error) => {
      if (error.code === 4001) {
        console.log('Please connect to MetaMask.');
      } else {
        console.error(error);
      }

      return false;
    });
};

const isConnectedToNetwork = async () => {
  return window.ethereum.isConnected();
};

const metamask = {
  isInstalled,
  addEventListeners,
  getAccount,
  isConnectedToNetwork
};

export default metamask;