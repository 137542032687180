import * as React from "react"
import styled from "styled-components"
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import metamask from "../../services/metamask";
import {useEffect, useState} from "react";
import metamaskIcon from "../../resources/img/icon/metamask.svg";
import breakpoints from "../../styles/breakpoints";
import {isBrowser} from "../../services/browser";
import storage from "../../services/storage";

const ConnectButton = styled.button`
  background: transparent;
  border: 2px solid ${colors.white};
  color: ${colors.white};
  ${fonts.gloryBold};
  font-size: 14px;
  border-radius: 80px;
  text-align: center;
  width: 152px;
  height: 32px;
  line-height: 32px;
  outline: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin-left: 12px;
  }

  &:hover {
    background: ${colors.white};
    color: ${colors.darkGreen};
    border-color: ${colors.darkGreen};
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: 16px;
    width: 168px;
    height: 40px;
    line-height: 40px;
  }
`;

const ConnectWallet = ({callback}) => {
  const [isMetamaskInstalled, setIsMetamaskInstalled] = useState(false);
  const [isMetamaskConnected, setIsMetamaskConnected] = useState(false);
  const [account, setAccount] = useState(null);

  useEffect(() => {
    async function onLoad() {
      if (!isBrowser()) {
        return;
      }

      const isInstalled = await metamask.isInstalled();

      if (isInstalled) {
        metamask.addEventListeners();
        setIsMetamaskInstalled(true);
      }

      const accountData = storage.getAccount();

      if (accountData && typeof accountData !== "undefined") {
        setAccount(accountData);
      }
    }

    onLoad();
  }, []);

  const getAccount = async () => {
    if (!checkIfConnectedToNetwork()) {
      return null;
    }

    return await metamask.getAccount();
  };

  const checkIfConnectedToNetwork = () => {
    if (!metamask || typeof metamask === "undefined") {
      setIsMetamaskConnected(false);

      return false;
    }

    const isConnectedToNetwork = metamask.isConnectedToNetwork();

    if (isConnectedToNetwork === false) {
      setIsMetamaskConnected(false);

      return false;
    }

    setIsMetamaskConnected(true);

    return true;
  };

  const connectWallet = async (e) => {
    e.preventDefault();

    if (isMetamaskInstalled === false) {
      return;
    }

    const accountData = await getAccount();

    if (accountData) {
      storage.connectAccount(accountData);

      const walletAddress = accountData[0];
      setAccount(walletAddress);

      callback(walletAddress);
    }
  };

  return (
    <ConnectButton onClick={connectWallet}>
      {account ?
        <>
          {`${account.substring(0, 7)}...`}
        </>
        :
        <>
          Connect wallet <img src={metamaskIcon} alt={"Connect wallet"}/>
        </>
      }
    </ConnectButton>
  )
};

export default ConnectWallet;
