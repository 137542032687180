import * as React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts";
import breakpoints from "../../styles/breakpoints";

const StyledQuestionH2 = styled.div`
  ${fonts.gloryBold};
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0;
  
  @media (min-width: ${breakpoints.md}) {
    font-size: 18px;
    line-height: 26px;
  }
`;

const QuestionTitle = ({children}) => {
  return (
    <StyledQuestionH2>
      {children}
    </StyledQuestionH2>
  )
};

export default QuestionTitle;
